let _MainNavigation;
let _ContactForm;
let _BlogPage;

jQuery(function ($) {
    _MainNavigation.init()
    _ContactForm.init()
    _BlogPage.init()
});

(($ => {
    _MainNavigation = {
        init(){

            const header = $('.nav-main');
            const navbar = $('#navbarToggleExternalContent');

            $(document).on('click', ":not(.nav-main #navbarToggleExternalContent)", function(){
                navbar.collapse('hide');
            })

            navbar.on('click', function(e){
                e.stopPropagation();
            });

            header.on('click', function(e){
                e.stopPropagation();
            })

            // Make Name in email = firstName + lastName from inputs.
            const contactForm = $('#schedule-appointment');
            if( contactForm.length ){
                let firstName = $('input#first-name');
                let lastName = $('input#last-name');
                let fromName = $('input#from-name');
                contactForm.on('submit', function(){
                    fromName.val(firstName.val() + ' ' + lastName.val())
                })
            }
            
            $(".vid-overlay").on("click",function(ev){
                let iSrc = $(this).parent().data('url');
                let iFrame = $(this).next();
                iFrame.attr('src', iSrc + '?autoplay=1&rel=0');
                $(this).addClass('d-none')

            });

        }

    }

    _ContactForm = {
        init(){
            const contactForm = $('#schedule-appointment');

            if( contactForm.length ){
                let recaptchaKey = contactForm.find('input[type="submit"]').data('key');
                
                // Recaptcha Token
                grecaptcha.ready(function() {
                    grecaptcha.execute(recaptchaKey, {action: 'submit'}).then(function(token) {
                        $('#g-recaptcha-response').val(token);
                    });
                });

                $('#from-page').val(window.location.href)

                const spinner = $('.spinner-border');
                let formControl = $('.form-control');
    
    
                let phoneNum = $('#phone-num');
                let emailAddress = $('#from-email');
                let emailReg = /^\S+@\S+\.\S+$/
                let currentVal = '';

                phoneNum.on('change focusout keyup keypress', function(e) {

                    currentVal = $(this).val()
                    if( e.code == 'Backspace' ){
                        currentVal = currentVal.slice(0, -1)
                    } else if ( parseInt( e.key ) && currentVal.length < 14) {
                        currentVal = $(this).val() + e.key
                    }
    
                    if ( currentVal.length - 4 != 10 && $(this).val() - 4 != 10 ){
                        $(this).removeClass('is-valid');
                        $(this).addClass('is-invalid');
                        $(this).next().removeClass('d-none')
                    } else {
                        $(this).removeClass('is-invalid');
                        $(this).addClass('is-valid');
                        $(this).next().addClass('d-none');
                    }
    
                    $(this).mask( '(000) 000-0000' );
                    
                })

                emailAddress.on('change focusout keyup keypress', function(e){

                    if( $(this).val().match(emailReg) ){
                        $(this).removeClass('is-invalid');
                        $(this).addClass('is-valid');
                        $(this).next().addClass('d-none')
                    } else {
                        $(this).removeClass('is-valid');
                        $(this).addClass('is-invalid');
                        $(this).is(':invalid');
                        $(this).next().removeClass('d-none');
                    }
                })

                formControl.not(emailAddress).not(phoneNum).on('focusout keypress change', function(){
                    let input = $(this);
                    setTimeout(function() {
    
                        if( input.is(':invalid') ){
                            input.removeClass('is-valid');
                            input.addClass('is-invalid');
                            input.next().removeClass('d-none');
                        } else {
                            input.removeClass('is-invalid')
                            input.addClass('is-valid');
                            input.next().addClass('d-none');
                        }
                    }, 0)
                });

                let isValid = true;
                contactForm.on('submit', function(e) {
                    e.preventDefault();
                    // Prevent the form from actually submitting
                    $(formControl).each(function(){
                        if($(this).is(':invalid')){
                        $(this).next().removeClass('d-none')
                        $(this).addClass('is-invalid');
                        }
                    })

                    if( $('.is-invalid').length === 0 ){
                        contactForm.addClass('was-validated');
                        isValid = true;
                    } else {
                        isValid = false
                    }
                   
                    if( isValid ){

                        $(".f-blocker").css('opacity', '.1')
                        $(':submit').attr('disabled', true)
                        spinner.removeClass('d-none');


                        // Send it to the server

                        $.post({
                            url: '/',
                            dataType: 'json',
                            data: $(this).serialize(),
                            success: function(response) {
                                
                                spinner.addClass('d-none');
                                $(formControl).each( function(){
                                    $(this).val('');
                                    $(this).removeClass('is-valid');
                                })
                                contactForm.removeClass('was-validated');
                                $('.thank-you').addClass('active')

                                setTimeout(function(){
                                    $('.thank-you').removeClass('active');
                                    $('.f-blocker').css('opacity', '1');
                                    $(':submit').attr('disabled', false);
                                }, 3000)
                                
                                                                
                            }, 
                            error: function(response) {
                                
                                spinner.addClass('d-none');
                                $('.error-message').addClass('active');

                                setTimeout(function(){
                                    $('.error-message').removeClass('active');
                                    $('.f-blocker').css('opacity', '1');
                                    $(':submit').attr('disabled', false);
                                }, 3000)
                            }
                    });
                    }
                });
            }
        }
    }


    _BlogPage = {
        init(){


            function showClearFiltersButton( activeFilters ) {
                if( activeFilters.length > 0 ) {
                    $(".clear-filters").removeClass("d-none")
                } else {
                    $(".clear-filters").addClass("d-none")
                }
            }

            // Check if blog index page
            if( $('body').hasClass('blogIndex') ) {

                let queryParams = new URLSearchParams(window.location.search);

                let allFilters = [];
                let activeFilters = JSON.parse( decodeURIComponent(queryParams.get('filters'))) != null ? JSON.parse(queryParams.get( 'filters' )) : [];
                let possibleFilters = [];

                let tagButtons = $('button.tag-filter');

                tagButtons.each(function(idx, el) {
                    allFilters.push($(this).data('tag'));
                })

                
               showClearFiltersButton(activeFilters)

                $('button.tag-filter').on('click', function(e) {
                    let filterBtn = $(this)

                    if( filterBtn.hasClass('active') ) {

                        if(activeFilters.includes(filterBtn.data('tag')) ) {
                            activeFilters = activeFilters.filter(x => x !== filterBtn.data('tag'))
                            queryParams.set("filters", JSON.stringify( activeFilters));

                            if( activeFilters.length === 0 ) {
                                window.history.replaceState(null, null, window.location.pathname);
                            } else {
                                history.replaceState(null, null, "?" + queryParams.toString());
                            }

                        }

                        $('.blog-post').each(function(idx, el) {
                            const blogTags = $(this).data('tags').split(', ')

                                const possibleTags = blogTags.filter(tag => !activeFilters.includes(tag))
                                const containsAll = activeFilters.every(el => blogTags.includes(el))

                                if ( possibleTags.length > 0 && containsAll ) {
                                    for ( const tag of possibleTags ) {
                                        if( !possibleFilters.includes(tag) ) {
                                            possibleFilters.push(tag)
                                        }
                                    }
                                }

                            let tags = blogTags.filter( x => activeFilters.includes(x) )

                            if( tags.length >= activeFilters.length || activeFilters.length === 0 ) {
                                $(this).removeClass('d-none')
                                filterBtn.removeClass('active')
                            }
                        })

                    } else {

                        $('.blog-post').each(function(idx, el) {
                            const blogTags = $(this).data('tags').split(', ')

                            if(!activeFilters.includes(filterBtn.data('tag')) ) {
                                activeFilters.push( filterBtn.data('tag') )
                                queryParams.set("filters", JSON.stringify(activeFilters));
                                history.replaceState(null, null, "?" + queryParams.toString());
                            }

                            if ( blogTags.includes( filterBtn.data('tag') ) ) {

                                const possibleTags =  blogTags.filter(tag => !activeFilters.includes(tag) )

                                const containsAll = activeFilters.every(el => blogTags.includes(el))

                                if ( possibleTags.length > 0 && containsAll ) {
                                    for ( const tag of possibleTags ) {
                                        if( !possibleFilters.includes(tag) ) {
                                            possibleFilters.push(tag)
                                        }
                                    }
                                }

                            }

                            filterBtn.addClass('active')

                            if( !blogTags.includes( filterBtn.data('tag') ) ) {
                                $(this).addClass('d-none')
                            }
                        })

                    }

                    showClearFiltersButton(activeFilters)


                    let impossibleFilters = allFilters.filter(tag => !activeFilters.includes(tag)).filter(tag => !possibleFilters.includes(tag))

                    // Disable filter buttons which cannot be filtered with current selection
                    for (const tag of impossibleFilters) {
                        $(`button.tag-filter[data-tag=${tag}]`).prop('disabled', true);
                    }

                    for (const tag of possibleFilters) {
                        $(`button.tag-filter[data-tag=${tag}]`).prop('disabled', false);
                    }

                    // Reset possible filters
                    possibleFilters = []

                })

                $('#clearFilters').on('click', function(e) {
                    activeFilters = [];
                    window.history.replaceState(null, null, window.location.pathname);

                    $('.blog-post.d-none').each(function(idx, el) {
                        $(this).removeClass('d-none')
                    })

                    $('.tag-filter.active').each(function(idx, el) {
                        $(this).removeClass('active')
                    })

                    $('.tag-filter:disabled').each(function(idx, el) {
                        $(this).prop('disabled', false)
                    })

                    showClearFiltersButton( activeFilters )
                })

                //To DO - Maybe add spinner so there is animation during filters load and then show section
                if( activeFilters.length > 0 ) {
                    for (const filterSlug of activeFilters) {
                        $(`button.tag-filter[data-tag=${filterSlug}]`).click()
                    }
                }

            }

        }
    }


    let swiperContainer = $('.swiper-container');

    if( swiperContainer.length ){
        var mySwiper = new Swiper('.swiper-container', {
            // Optional parameters
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
          
            // If we need pagination
            pagination: {
                clickable: true,
                el: '.swiper-pagination',
            },
          
            // Navigation arrows
            // navigation: {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev',
            // },
        
        })
    }
    

}))(jQuery);
